import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import BlogCardOne from '../../components/PageComponents/BlogCardOne'
import Header from '../../components/shared/Header'
import { nanoid } from 'nanoid'
import FooterSection from '../../components/PageComponents/FooterSection'
import ThemeChanger from '../../components/shared/ThemeChanger'
import Layout from '../../components/StructureComponents/Layout'
import _, { slice } from 'lodash'
import { Link } from '@reach/router'
import Slider from 'react-slick'
import FaqSecondary from '../../components/shared/FaqSecondary'
import Title from '../../components/StructureComponents/Title'
/**
 *
 * @param {blogs} object
 */

let carouselSettings = {
  dots: false,
  infinite: true,
  speed: 300,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const activateCotItemOnScroll = () => {
  if (window && document) {
    const sections = document.querySelectorAll('h2[id]')
    console.log(
      'Available heading IDs:',
      Array.from(sections).map((s) => s.id)
    )

    window.addEventListener('scroll', navHighlighter)

    function navHighlighter() {
      let scrollY = window.pageYOffset
      let currentActiveId = null

      for (let i = sections.length - 1; i >= 0; i--) {
        const current = sections[i]
        const sectionTop = current.offsetTop - 100

        if (scrollY >= sectionTop) {
          currentActiveId = current.id
          break
        }
      }

      const tocLinks = document.querySelectorAll('.toc-header a')
      tocLinks.forEach((link) => link.classList.remove('active'))
      if (currentActiveId) {
        const activeLink = document.querySelector(
          `.toc-header a[href="#${CSS.escape(currentActiveId)}"]`
        )
        if (activeLink) {
          activeLink.classList.add('active')
        } else {
          console.log(
            `Could not find TOC link for heading with ID: ${currentActiveId}`
          )
        }
      }
    }
  }
}

const SingleBlog = (props) => {
  useEffect(() => {
    activateCotItemOnScroll()
  }, [])
  const headings = props.data.blog.headings

  const checkIfTwoArrayshaveSameElement = (arr1, arr2) =>
    arr1.some((val) => arr2.includes(val))

  const blogData = props?.data?.blog?.frontmatter

  const filteredBlogs = props.data.blogs.edges.filter((blog) => {
    return (
      checkIfTwoArrayshaveSameElement(
        blogData.categories,
        blog.node.frontmatter.categories
      ) &&
      _.kebabCase(blog.node.frontmatter.title) !== _.kebabCase(blogData.title)
    )
  })
  const [blogState, setBlogState] = useState(filteredBlogs)
  const renderbreadcrumb = (title) => {
    return (
      <div className="breadcrumb-wrap">
        <Link to="/" className="parent-page">
          Home
        </Link>
        {'>'}
        <Link to="/blog/" className="parent-page">
          Blog
        </Link>
        {'>'}
        <span className="current-page">{title}</span>
      </div>
    )
  }

  const metaToShow = {
    ...props?.data?.blog?.frontmatter?.meta,
    canonicalLink: props?.data?.blog?.frontmatter?.meta?.canonicalLink
      ? props?.data?.blog?.frontmatter?.meta?.canonicalLink
      : `/blog/${props?.data?.blog?.frontmatter?.slug}` || '/'
  }

  const renderTableOfContent = (headings) => {
    const handleMakeActive = (e) => {
      e.preventDefault()

      const current = e.target
      const refVal = current.getAttribute('href').replace('#', '')

      console.log('Clicked TOC link for:', refVal)

      // Scroll to the element smoothly
      const targetElement = document.getElementById(refVal)
      if (targetElement) {
        console.log('Found target element:', targetElement)
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })

        // Update the active class in TOC
        document.querySelectorAll('.toc-link').forEach((item) => {
          item.classList.remove('active')
        })
        current.classList.add('active')

        // Update the URL hash without reloading the page
        window.history.pushState(null, null, `#${refVal}`)
      } else {
        console.error('Target element not found for ID:', refVal)

        // Try to find the element with a similar ID (case insensitive)
        const allHeadings = document.querySelectorAll('h2[id]')
        console.log(
          'All heading IDs:',
          Array.from(allHeadings).map((h) => h.id)
        )

        // Try to find a close match
        const matchingHeading = Array.from(allHeadings).find(
          (h) =>
            h.id.toLowerCase() === refVal.toLowerCase() ||
            h.id.toLowerCase().replace(/-/g, '') ===
              refVal.toLowerCase().replace(/-/g, '')
        )

        if (matchingHeading) {
          console.log('Found similar heading:', matchingHeading.id)
          matchingHeading.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })

          // Update active class
          document.querySelectorAll('.toc-link').forEach((item) => {
            item.classList.remove('active')
          })
          current.classList.add('active')

          // Update URL hash
          window.history.pushState(null, null, `#${matchingHeading.id}`)
        }
      }
    }

    return (
      <div className="tbc-wrap cta-section">
        <Title tag="h4" classes="title" src="Table Of Contents" />
        {headings
          .filter((item) => item.depth === 2)
          .map((toc, index) => {
            let refVal = toc.value
              .toLowerCase()
              .replace(/&/g, '-and-')
              .replace(/[^a-z0-9\s-]/g, '')
              .trim()
              .replace(/\s+/g, '-')
              .replace(/--+/g, '-')

            if (toc.value.includes('&')) {
              const doubleHyphenVersion = toc.value
                .toLowerCase()
                .replace(/&/g, '--')
                .replace(/[^a-z0-9\s-]/g, '')
                .trim()
                .replace(/\s+/g, '-')
                .replace(/---+/g, '--')

              if (doubleHyphenVersion.includes('--')) {
                refVal = doubleHyphenVersion
              }
            }

            return (
              <h5 className="toc-header" key={index}>
                <a
                  href={`#${refVal}`}
                  className="toc-link"
                  onClick={handleMakeActive}
                  data-original-text={toc.value}
                >
                  {toc.value}
                </a>
              </h5>
            )
          })}
      </div>
    )
  }

  const renderSidebarCTA = () => {
    return (
      <div className="sidebar-cta-wrap">
        {headings && renderTableOfContent(headings)}
      </div>
    )
  }

  return (
    <>
      <Layout
        meta={metaToShow}
        alternateOgImage={blogData?.featuredimage?.image}
      >
        <div className="single__page_two">
          <Header />
          <ThemeChanger />

          <div className="maxWidthcontainer">
            <main className="wrapper__mainside">
              <div className="wraper__single__page">
                <div className="left__side_main">
                  <div className="left__side_main_top">
                    {/* <div className="top-container">
                      <div className="content-wrapper">
                        <div className="content-left">
                          {renderbreadcrumb(blogData.title)}
                          <h1 className="main_heading">{blogData.title}</h1>
                          <div className="Date__author__tags">
                            <div className="Date">{blogData.date}</div>
                            <div className="author">
                              <a
                                href={`/author/${_.kebabCase(blogData.author)}`}
                                className="name"
                              >
                                {blogData.author}
                              </a>
                            </div>
                            <div className="tags">
                              {blogData.categories.map((category) => (
                                <button className="tag-btn" key={nanoid()}>
                                  <a
                                    href={`/category/${_.kebabCase(category)}/`}
                                    className="tag"
                                  >
                                    {category}
                                  </a>
                                </button>
                              ))}
                            </div>
                          </div>
                          <div className="main__img">
                            <img
                              src={blogData.featuredimage?.image}
                              alt={blogData.featuredimage?.alt}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="bottom-container">
                      <div className="sidebar">{renderSidebarCTA()}</div>
                      <div className="content-right">
                      <div className="content-left">
                          {renderbreadcrumb(blogData.title)}
                          <h1 className="main_heading">{blogData.title}</h1>
                          <div className="Date__author__tags">
                            <div className="Date">{blogData.date}</div>
                            <div className="author">
                              <a
                                href={`/author/${_.kebabCase(blogData.author)}`}
                                className="name"
                              >
                                {blogData.author}
                              </a>
                            </div>
                            <div className="tags">
                              {blogData.categories.map((category) => (
                                <button className="tag-btn" key={nanoid()}>
                                  <a
                                    href={`/category/${_.kebabCase(category)}/`}
                                    className="tag"
                                  >
                                    {category}
                                  </a>
                                </button>
                              ))}
                            </div>
                          </div>
                          <div className="main__img">
                            <img
                              src={blogData.featuredimage?.image}
                              alt={blogData.featuredimage?.alt}
                            />
                          </div>
                        </div>
                        <div
                          className="righ__side__desc"
                          dangerouslySetInnerHTML={{
                            __html: props?.data?.blog?.html
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* // Similar Blogs Section  */}
                  <div className="similarBlogsCarousal">
                    <h2 className="similarBlogsCarousal__title">
                      Similar Blogs
                    </h2>
                    <Slider {...carouselSettings}>
                      {blogState?.slice(0, 4)?.map((blog) => (
                        <div className="similarBlogsCarousal__itemWrap">
                          <BlogCardOne
                            {...blog.node.frontmatter}
                            html={blog.node.html}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  {/* Similar Blogs Section  */}
                </div>
              </div>
            </main>
          </div>

          {props?.data?.blog?.frontmatter?.faq && (
            <FaqSecondary faqs={props?.data?.blog?.frontmatter?.faq} />
          )}

          <FooterSection
            {...props?.data?.indexPage?.frontmatter?.footersection}
          />
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleBlog($id: String!) {
    indexPage: markdownRemark(frontmatter: { template: { eq: "HomePage" } }) {
      frontmatter {
        footersection {
          primaryHeading
          description
          secondaryheading
          tertiaryheading
          footerList {
            primaryheading
            footerLink
          }
        }
      }
    }

    blogs: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "blogs" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            slug
            date(formatString: "MMM D YYYY")
            categories
            status
            author
            description
            featuredimage {
              image
              alt
            }
          }
        }
      }
    }
    blog: markdownRemark(id: { eq: $id }) {
      id
      headings {
        value
        depth
      }
      html
      frontmatter {
        meta {
          title
          description
          ogimage
          canonicalLink
        }

        faq {
          question
          answer
        }

        categories
        title
        slug
        date(formatString: "MMM D YYYY")

        author
        featuredimage {
          image
          alt
        }
      }
      frontmatter {
        title
      }
    }
    #END OF Query
  }
`

export default SingleBlog
