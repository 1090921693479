import React from 'react'
import { titleCase } from 'title-case'

import './title.scss'

export default function Title({
  src = '',
  tag = '',
  classes = '',
  showTitleCase = false
}) {
  src = src.replace('&#038;', '&')

  if (showTitleCase === true) {
    src = titleCase(src)
  }

  if (tag === 'h1') {
    return (
      <h1
        // itemProp="title"
        className={`title ${classes} `}
        dangerouslySetInnerHTML={{ __html: src }}
      ></h1>
    )
  }
  if (tag === 'h2') {
    return (
      <h2
        itemProp="title"
        className={`title ${classes}`}
        dangerouslySetInnerHTML={{ __html: src }}
      ></h2>
    )
  }
  if (tag === 'h3') {
    return (
      <h3
        className={`title ${classes}`}
        dangerouslySetInnerHTML={{ __html: src }}
      ></h3>
    )
  }
  if (tag === 'h4') {
    return (
      <h4
        className={`title ${classes}`}
        dangerouslySetInnerHTML={{ __html: src }}
      ></h4>
    )
  }
  if (tag === 'h5') {
    return (
      <h5
        className={`title ${classes}`}
        dangerouslySetInnerHTML={{ __html: src }}
      ></h5>
    )
  }
  if (tag === 'h6') {
    return (
      <h6
        className={`title ${classes}`}
        dangerouslySetInnerHTML={{ __html: src }}
      ></h6>
    )
  }
  if (tag === 'p') {
    return (
      <p
        className={`title ${classes}`}
        dangerouslySetInnerHTML={{ __html: src }}
      />
    )
  }

  return (
    <h1
      itemProp="title"
      className={`title ${classes}`}
      dangerouslySetInnerHTML={{ __html: src }}
    ></h1>
  )
}
